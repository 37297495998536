// export const firebaseConfig = {
//   apiKey: 'AIzaSyAptyqUzFdzuhsO7WQWXUQfbqQDr2Xm50o',
//   authDomain: 'askhippo-development.firebaseapp.com',
//   databaseURL: 'https://askhippo-development.firebaseio.com',
//   projectId: 'askhippo-development',
//   storageBucket: 'askhippo-development.appspot.com',
//   messagingSenderId: '914822170849',
//   measurementId: 'G-2YZSS93TTT',
//   appId: '1:914822170849:web:698dbf7804636986da8fa0'
// };

// export const apiURL = 'https://us-central1-askhippo-development.cloudfunctions.net/';
// export const environment = {
//   production: true,
//   isMockEnabled: true, // You have to switch this, when your real back-end is done
//   authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
//   ipURL: 'https://www.askipo.com/api/ip',
//   cloudFunctionUrl: 'https://us-central1-askhippo-development.cloudfunctions.net/',
//   apiURL: 'https://us-central1-askhippo-development.cloudfunctions.net/',
//   otherApiURL: 'https://maradona.askipo.com/api.development/',
//   agoraAppId: '2149a3ad83fd43c1ae5fdbc00a64884f'
// };

// -
export const environment = {
  production: true,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  ipURL: 'https://www.askipo.com/api/ip',
  cloudFunctionUrl: 'https://us-central1-askipo-production.cloudfunctions.net/',
  apiURL: 'https://us-central1-askipo-production.cloudfunctions.net/',
  otherApiURL: 'https://askipo.com/api/',
    agoraAppId: '2149a3ad83fd43c1ae5fdbc00a64884f'
};
export const firebaseConfig = {
  apiKey: 'AIzaSyBvwa8G2iTpLyzBsusdwbFemJ28fyswfko',
  authDomain: 'askipo-production.firebaseapp.com',
  databaseURL: 'https://askipo-production.firebaseio.com',
  projectId: 'askipo-production',
  storageBucket: 'askipo-production.appspot.com',
  messagingSenderId: '1061987882152',
  appId: '1:1061987882152:web:ac357395c5f1f243921e9d',
  measurementId: 'G-9CNZ0SE0G8'
};

export const apiURL = 'https://us-central1-askipo-production.cloudfunctions.net/';
