import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AgoraService } from '@core/services/agora.service';
import { UserStore } from '@core/store/user.store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ThreadService } from '../../../../core/services/thread.service';

interface CustomVideoElement extends HTMLVideoElement {
  requestPictureInPicture(): any;
  exitPictureInPicture(): void;
  pictureInPictureElement: any;
}

@Component({
  selector: 'kt-webrtc',
  templateUrl: './webrtc.component.html',
  styleUrls: ['./webrtc.component.scss']
})
export class WebrtcComponent implements OnInit {
  @Input()
  public threadID: string;

  @Input()
  public userUID: string;

  @Input()
  public isDoctor: boolean;

  public remoteStatus: boolean;

  public micStatus: boolean;
  public videoStatus: boolean;
  public pipStatus: boolean;
  public isStreamPublished: boolean = false;
  private threadSub: Subscription;

  @ViewChild('localVideo', { static: true }) localVideo: ElementRef;
  @ViewChild('remoteVideo', { static: true }) remoteVideo: ElementRef;

  constructor(
    private threadService: ThreadService,
    private store: UserStore,
    public agoraService: AgoraService
  ) {
    this.micStatus = true;
    this.videoStatus = true;
    this.pipStatus = false;
  }

  async ngOnInit() {
    try {
      if (!this.localVideo.nativeElement || !this.remoteVideo.nativeElement) {
        throw new Error('Video elements not found');
      }
  
      this.agoraService.setVideoElements(
        this.localVideo.nativeElement,
        this.remoteVideo.nativeElement
      );
  
      // Browser izinlerini kontrol edelim
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      });
  
      // Stream'in track'lerini kontrol edelim
  
      await this.agoraService.initialize(true);
  
      if (!this.isDoctor) {
        this.threadSub = this.threadService.thread(this.threadID).pipe(
          map((value) => {
            this.remoteStatus = value['connect_status']['doctor'];
            if (this.remoteStatus && value['connect_status']['patient']) {
              setTimeout(async () => {
                try {
                  await this.agoraService.initialize(true);
                } catch (error) {
                  console.error('Error initializing call:', error);
                  this.finishCall();
                }
              }, 1000);
            }
          })
        ).subscribe();
      }

      this.agoraService.isStreamPublished$.subscribe((res) => {
        this.isStreamPublished = res;
      });
  
    } catch (error) {
      console.error('Error in ngOnInit:', error);
      this.finishCall();
    }
  }
  

  public finishCall() {
    this.agoraService.closeChannel(this.threadID);
    this.threadService.setActiveThread(null);
  }

  public changeMic() {
    if (this.micStatus) {
      this.agoraService.muteAudio();
      this.micStatus = false;
    } else {
      this.agoraService.unmuteAudio();
      this.micStatus = true;
    }
  }

  public changeVideo() {
    if (this.videoStatus) {
      this.agoraService.muteVideo();
      this.videoStatus = false;
    } else {
      this.agoraService.unmuteVideo();
      this.videoStatus = true;
    }
  }

  ngOnDestroy() {
    this.finishCall();

    if (this.threadSub) {
      this.threadSub.unsubscribe();
    }
  }

  fullScreen() {
    const rtcElement = document.getElementById('rtc');
    if (document.fullscreenElement) {
      document.exitFullscreen().then(() => {
      }).catch((err) => {
        console.error('[WebrtcComponent] Error exiting full screen:', err);
      });
    } else if (rtcElement) {
      rtcElement.requestFullscreen().then(() => {
      }).catch((err) => {
        console.error('[WebrtcComponent] Error entering full screen:', err);
      });
    } else {
      console.error('[WebrtcComponent] RTC element not found for full screen.');
    }
  }

  public pictureInPicture() {
    try {
      const element = document.getElementById('remote-video') as CustomVideoElement; // Correct ID
      if (!element) {
        console.error('[WebrtcComponent] Remote video element not found for Picture-in-Picture.');
        return;
      }

      if (!this.pipStatus) {
        element.requestPictureInPicture().then(() => {
          this.pipStatus = true;
        }).catch((err) => {
          console.error('[WebrtcComponent] Error entering Picture-in-Picture mode:', err);
        });
      } else {
        (document as any).exitPictureInPicture().then(() => {
          this.pipStatus = false;
        }).catch((err) => {
          console.error('[WebrtcComponent] Error exiting Picture-in-Picture mode:', err);
        });
      }
    } catch (reason) {
      console.error('[WebrtcComponent] Picture-in-Picture error:', reason);
    }
  }
}

