import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Appointment } from '@core/models/appointment.model';
import { Note } from '@core/models/note.model';
import { Package } from '@core/models/package.model';
import { WeightInfo } from '@core/models/weight-info.model';
import { UserStore } from '@core/store/user.store';
import { addMonths } from 'date-fns';
import firebase from 'firebase/app';
import moment from 'moment';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from './../auth/_models/user.model';
import { Review } from './../models/review.model';
import { AnalyticsService } from './analytics.service';
import { ThreadService } from './thread.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private fireStore: AngularFirestore,
    private store: UserStore,
    private http: HttpClient,
    private threadService: ThreadService,
    private analyticsService: AnalyticsService,
    private httpClient: HttpClient,
    private fireAuth: AngularFireAuth
  ) {
  }

    private async getHttpHeader() {
      const firebaseUser = await this.fireAuth.user.pipe(
        first()
      ).toPromise();
      console.log('firebaseUser:',firebaseUser);
      const token = await firebaseUser.getIdToken();
  
      return new HttpHeaders({
        'Authorization': 'Bearer ' + token,
      });
    }

  public getUID() {
    return this.store.getValue().user.uid;
  }

  public doctors() {
    return this.fireStore.collection('users', query => query.where('is_doctor', '==', true)).valueChanges({
      idField: 'uid'
    }) as Observable<User[]>;
  }

  public packages() {
    return this.fireStore.collection('packages', (query) =>
      query.where('enabled', '==', true)
    ).valueChanges({
      idField: 'id'
    }) as Observable<Package[]>;
  }

  // public myPackages(uid: string, doctorUid: string) {
  //   return this.fireStore.collection('package_tracking').ref.where('uid', '==', uid).where('pkg_author_id', '==', doctorUid).get();
  // }

  public async myPackages(uid: string, doctorUid: string) {
    return this.httpClient.get(`${environment.otherApiURL}expert/userpackage/` + uid + '/' + doctorUid, 
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise();
  }

  public order(orderID: string) {
    return this.fireStore.collection('orders').doc(orderID).valueChanges();
  }

  public appointments() {
    return this.fireStore.collection('appointments').valueChanges({
      idField: 'id'
    }) as Observable<Appointment[]>;
  }

  public getPackage(uid: string) {
    const user = this.store.getValue().user;
    return this.fireStore
      .collection('package_tracking').ref.orderBy('start_date', 'desc')
      .where('uid', '==', uid)
      .where('pkg_author_id', '==', user.uid).get();
  }

  public async hasEnabledPackage(uid: string) {
    const user = this.store.getValue().user;
    const docRef = await this.fireStore
      .collection('package_tracking').ref
      .where('uid', '==', uid)
      .where('pkg_author_id', '==', this.getUID())
      .where('enabled', '==', true)
      .get();

    return !(docRef.empty);
  }

  public hasPreMet(assignedUID: string, authorUID: string) {
    return this.fireStore.collection('appointments', query => query
      .where('assigned_uid', '==', assignedUID)
      .where('author_uid', '==', authorUID)
      .where('pre_meeting', '==', true))
      .valueChanges();
  }

  public futureAppointments(assignedUID: string) {
    const now = firebase.firestore.Timestamp.now().toMillis();
    return this.fireStore.collection('appointments', query => query
      .where('assigned_uid', '==', assignedUID)
      .where('date', '>', now)).valueChanges();
  }

  public reviews() {
    const uid = this.store.getValue().user.uid;
    return this.fireStore.collection('reviews', query => query.where('author.uid', '==', uid))
      .valueChanges({ idField: 'id' }) as Observable<Review[]>;
  }

  public updateComment(id: string, comment: string) {
    return this.fireStore.collection('reviews').doc(id).update({
      comment
    });
  }

  public getLatestAppointment(doctorUID: string) {
    const uid = this.store.getValue().user.uid;
    return this.fireStore.collection('appointments').ref
      .where('assigned_uid', '==', uid)
      .where('author_uid', '==', doctorUID)
      .orderBy('date', 'asc').limit(1).get();
  }

  public async havePackage2(authorUID: string = 'none') {
    const user = this.store.getValue().user;

    // Son satın alınan paketi çekiyoruz her türlü. create_date ve limit sayesinde.
    let query = this.fireStore.collection('package_tracking')
      .ref.orderBy('start_date', 'desc')
      .where('assigned_user.uid', '==', user.uid)
      .limit(1);

    if (authorUID !== 'none') {
      // Herhangi bir diyetisyene ait paketi var mı kontrolü.
      query = query.where('author_uid', '==', authorUID);
    }

    const result = await query.get();

    // Paketi yoksa
    if (result.empty) {
      return false;
    }

    const document = result.docs[0];
    const now = firebase.firestore.Timestamp.now();
    const packages = document.get('packages');
    const lastPackage = packages[packages.length - 1];

    const packageStartDate = new Date(lastPackage['purchase_date']);
    const packageEndDate = packageStartDate.setMonth(
      packageStartDate.getMonth() + lastPackage['months'].length
    );

    return packageEndDate > now.toMillis();
  }

  public getNextAppointment(doctorUID: string) {
    const uid = this.store.getValue().user.uid;
    const now = firebase.firestore.Timestamp.now().toMillis();
    return this.fireStore.collection('appointments').ref
      .where('assigned_uid', '==', uid)
      .where('author_uid', '==', doctorUID)
      .where('date', '>', now)
      .orderBy('date', 'asc').limit(1).get();
  }


  public getDoctor(uid: string) {
    return this.fireStore.collection('public_users').doc(uid).valueChanges() as Observable<User>;
  }

  public getReviewsOfDoctor(uid: string) {
    return this.fireStore.collection('reviews', query =>
      query.where('doctor.uid', '==', uid))
      .valueChanges({ idField: 'id' }) as Observable<Review[]>;
  }

  public getPackageOfDoctor(uid: string) {
    return this.fireStore.collection('packages',
      query => query.where('author.uid', '==', uid)
    ).valueChanges({ idField: 'id' }) as Observable<Package[]>;
  }

  public getAppointments(uid?: string) {
    if (!uid) {
      uid = this.store.getValue().user.uid;
    }
    // console.log('uid:', uid);
    return this.fireStore.collection('appointments', query => query.where('assigned_uid', '==', uid).orderBy('date', 'desc'))
      .valueChanges({ idField: 'id' }) as Observable<Appointment[]>;
  }

  public activeAppointment() {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();

    const uid = this.store.getValue().user.uid;
    return this.fireStore.collection('appointments', query =>
      query.where('assigned_uid', '==', uid)
        .where('date', '>=', startDate.getTime())
        .where('date', '<=', endDate.getTime())
    )
      .valueChanges({ idField: 'id' }) as Observable<Appointment[]>;
  }

  public getAppointmentsOf(uid: string) {
    const now = firebase.firestore.Timestamp.now().toMillis();

    return this.fireStore.collection('appointments', query =>
      query
        .where('author_uid', '==', uid)
        .where('assigned_uid', '==', 'none')
        .where('date', '>', now))
      .valueChanges({ idField: 'id' }) as Observable<Appointment[]>;
  }

  public incomingAppointments() {
    return this.fireStore.collection('appointments', query => query.where('date', '>', new Date().getTime())
    ).valueChanges({
      idField: 'id'
    }) as Observable<Appointment[]>;
  }

  /**
   * Randevuya kayıt olma işlemini yapar.
   * @param id Randevu ID'si
   * @param doctorUID Doktor UID'si
   * @param type Randevu tipi
   * @param pre_meeting Ön görüşme
   */
  // public async appoint(id: string, doctorUID: string, type: string, pre_meeting = false) {
  //   const uid = this.store.getValue().user.uid;
  //   const _pkg = _.cloneDeep(this.store.getValue().package);

  //   let pkg;
  //   let monthIndex;
  //   let activeIndex;
  //   const timestamp = firebase.firestore.Timestamp.now().toMillis();

  //   if (!pre_meeting && _pkg.author_uid === doctorUID) {
  //     pkg = _pkg['activePackage'];
  //     activeIndex = _pkg.packages.indexOf(pkg);
  //     monthIndex = pkg.months.findIndex((m) => moment(timestamp).isBetween(m.start_date, addMonths(m.start_date, 1)));
  //     if (monthIndex !== -1) {
  //       if (pkg.months[monthIndex].meetings === 0) {
  //         throw new Error('no-meetings-left');
  //       }
  //       _pkg.packages[activeIndex].months[monthIndex].meetings--;
  //     }
  //   }

  //   // if (monthIndex === -1 || pkg.months[monthIndex].meetings === 0) {
  //   //   throw new Error('package-not-found');
  //   // }

  //   const thread = await this.fireStore
  //     .collection('threads').ref
  //     .where('doctor.uid', '==', doctorUID)
  //     .where('patient.uid', '==', uid).get();

  //   try {
  //     const user = await this.store.getValue().user;
  //     const appointResult = await this.fireStore.collection('appointments').ref
  //       .where('assigned_uid', '==', user.uid).get();

  //     if (appointResult.docs.length === 2) {
  //       this.analyticsService.logEvent('create_appointment', {
  //         doctor_id: doctorUID,
  //         patient_id: user.uid,
  //         title: 'İkinci Randevu Alındı'
  //       });
  //     }
  //   } catch (error) {
  //     console.log('Event kayıt edilirken hata oluştu.', error);
  //   }

  //   return this.fireStore.collection('appointments').doc(id).update({
  //     assigned_uid: uid,
  //     // thread_id: thread.docs[0].id,
  //     pre_meeting,
  //     type
  //   });

  //   if (pkg) {
  //     // console.log('pkg_', _pkg);
  //     // await this.fireStore.collection('user_packages').doc(pkg.id).update(_pkg);
  //   }
  // }

  public async subscribePackage(doctorUID: string, pack: { meetings, months, price }) {
    const now = firebase.firestore.Timestamp.now().toMillis();
    let months: any[];
    // console.log('pack:', pack);
    months = Array(pack.months).fill(pack.months).map((month, i) => {
      const start = +addMonths(now, i);
      const end = +addMonths(now, i + 1);
      return {
        start,
        meetings: pack.meetings,
        end
      };
    });
    // console.log('months:', months);
    const { uid, display_name, profile_image } = this.store.getValue().user;
    await this.fireStore.collection('user_packages').add({
      assigned_user: {
        uid, display_name, profile_image
      },
      months,
      price: pack.price,
      purchase_date: now,
      uid: doctorUID
    });
    return this.threadService.createThread(doctorUID);
  }

  public get ipAddress() {
    return this.http.get(environment.ipURL).toPromise() as Promise<string>;
  }

  public getNotes(patientId: string, doctorUID: string): Observable<Note[]> {
    return this.fireStore.collection('users').doc(patientId)
      .collection<Note>('notes', (q) =>
        q.where('author_uid', '==', doctorUID)
      )
      .valueChanges();
  }

  public addNoteToPatient(patientId: string, noteDocumentContent: Note) {
    const patientRef = this.fireStore.collection('users').doc(patientId).ref;

    return patientRef.collection('notes').add({
      ...noteDocumentContent,
    });
  }

  public getWeightInfo(patientId: string, doctorUID: string)
    : Promise<WeightInfo[]> {
    return this.fireStore.collection('users').doc(patientId)
      .collection('weights')
      .ref.where('author_uid', '==', doctorUID)
      .orderBy('date', 'desc')
      .get()
      .then((snapshot) => {
        const weightInfos: WeightInfo[] = [];

        for (const doc of snapshot.docs) {
          weightInfos.push({
            id: doc.id,
            ...doc.data(),
          } as WeightInfo);
        }

        return weightInfos;
      })
  }

  public addWeightInfo(patientId: string, weightDocumentContent: any) {
    const patientRef = this.fireStore.collection('users').doc(patientId).ref;

    return patientRef.collection('weights').add({
      ...weightDocumentContent,
    });
  }
}
