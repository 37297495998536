import {AuthService} from './../../../../core/auth/_services/auth.service';
// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

// Auth
import {Subject} from 'rxjs';

import {UserStore} from '@core/store/user.store';
import firebase from 'firebase/app';
import moment from 'moment';
import Swal from 'sweetalert2';
import {AuthNoticeService} from '@core/auth';
import {AnalyticsService} from '@core/services/analytics.service';
import {SwalProvider} from '@core/providers/swal.provider';
import CityList from '@assets/data/cityList.json';
import CityDistrictList from '@assets/data/cityDistrict.json';

@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  loading = false;
  errors: any = [];

  fullPhone: string;

  // errors
  emailInUse = false;
  ageNotValid = false;
  notAgreed = false;

  role: string;

  licenceHTML: string;
  kvkkHTML: string;
  licenceTick;
  kvkkTick;

  months = [
    'Ocak', 'Şubat', 'Mart',
    'Nisan', 'Mayıs', 'Haziran',
    'Temmuz', 'Ağustos', 'Eylül',
    'Ekim', 'Kasım', 'Aralık'
  ];

  cities: Array<any>;
  districts: Array<any>;

  cv: File;

  stepNumber = 0;

  private unsubscribe: Subject<any>;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private store: UserStore,
    private authNoticeService: AuthNoticeService,
    private analyticsService: AnalyticsService
  ) {
    this.unsubscribe = new Subject();
  }

  async ngOnInit() {
    this.cities = CityList.map((city) => ({
      text: city.name,
      id: city.name
    }) as any);

    this.role = this.store.getValue().data;

    if (!this.role) {
      this.router.navigate(['/auth/role']);
    }
    this.initRegisterForm();
    this.fullPhone = this.route.snapshot.paramMap.get('phone');

    if (this.fullPhone == null) {
      this.router.navigate(['/auth/login']);
      return;
    }

    if (this.role === 'doctor') {
      this.licenceTick = true;
    } else {
      this.licenceTick = false;
    }

    const contracts = await this.auth.getContracts();
    this.licenceHTML = contracts.data().user;
    this.kvkkHTML = contracts.data().kvk;
    this.authNoticeService.setDescription(
      this.role === 'patient'
        ? `Askipo'yla dilediğin yerden diyetisyenine ulaşabilir ve menünü yönetebilirsin.`
        : `Askipo'yla dilediğin yerden danışanlarınla online görüşme yapabilir ve menülerini yönetebilirsin.`
    );
    this.cd.markForCheck();
  }

  onChangeCity(value) {
    this.districts = CityDistrictList.data
      .find((city) => city.il_adi === value).ilceler
      .map((district) => district.ilce_adi)
      .sort((a, b) => a.localeCompare(b)) as any[];
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  showKvkk() {
    Swal.fire({
      html: '<div class="user-licence">' + this.kvkkHTML + '</div>',
      showConfirmButton: false
    });
  }

  showLicence() {
    Swal.fire({
      html: '<div class="user-licence">' + this.licenceHTML + '</div>',
      showConfirmButton: false
    });
  }

  handleNext() {
    if (this.stepNumber === 0 && (
      !this.canGoNext('firstname', 'required') &&
      !this.canGoNext('lastname', 'required') &&
      !this.canGoNext('email', 'required') &&
      !this.canGoNext('school', 'required')
    )) {
      this.stepNumber++;
    } else if (this.stepNumber === 1 && (
      !this.canGoNext('dietExperience', 'required') &&
      !this.canGoNext('dietExperience', 'maxLength') &&
      !this.canGoNext('onlineDietExperience', 'required') &&
      !this.canGoNext('onlineDietExperience', 'maxLengt') &&
      !this.canGoNext('numberOfFollowers', 'maxLength') &&
      !this.canGoNext('socialMediaLinks', 'maxLength')
    )) {
      this.stepNumber++;
    } else if (this.stepNumber === 2 && (
      !this.canGoNext('cv', 'required') &&
      !this.canGoNext('city', 'required') &&
      !this.canGoNext('district', 'required')
    )) {
      this.stepNumber++;
    }
  }

  handlePrevious() {
    this.stepNumber--;
  }

  initRegisterForm() {
    if (this.role === 'patient') {
      this.registerForm = this.fb.group({
        display_name: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email
          ])
        ],
        day: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(31)])],
        month: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(12)])],
        year: [null, Validators.compose([Validators.required, Validators.min(new Date().getFullYear() - 100), Validators.max(new Date().getFullYear() - 18)])],
      });
    } else {
      this.registerForm = this.fb.group({
        firstname: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ],
        lastname: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email
          ])
        ],
        school: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ],
        dietExperience: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(5000)
          ])
        ],
        onlineDietExperience: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(5000)
          ])
        ],
        numberOfFollowers: [
          '',
          Validators.compose([
            Validators.maxLength(5000)
          ])
        ],
        socialMediaLinks: [
          '',
          Validators.compose([
            Validators.maxLength(5000)
          ])
        ],
        cv: [
          null,
          Validators.compose([
            Validators.required,
          ])
        ],
        city: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ],
        district: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ],
      });
    }
  }

  handleChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.cv = event.target.files[0];
      this.registerForm.controls.cv.setValue(this.cv);
    }
  }

  async submit() {
    this.emailInUse = false;
    this.ageNotValid = false;

    if (!this.kvkkTick || !this.licenceTick) {
      this.notAgreed = true;
      return;
    }
    this.notAgreed = false;

    let birthDate = null;
    const controls = this.registerForm.controls;

    if (this.role === 'patient') {
      birthDate = new Date(`${controls.month.value + 1}/${controls.day.value}/${controls.year.value}`);
    }

    console.log(birthDate);

    const phone = '+9' + this.fullPhone
      .replace('(', '')
      .replace(')', '')
      .replace(/\s/g, '');

    // check form
    if (this.registerForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      SwalProvider.fire('Uyarı', 'Lütfen bilgileri tam girdiğinizden emin olun.', 'warning');
      // console.log('not valid');
      return;
    }

    this.loading = true;

    if (this.role === 'patient') {
      const now = firebase.firestore.Timestamp.now().toMillis();
      const age = moment(now).diff(birthDate, 'years');

      if (age < 18) {
        this.ageNotValid = true;
        this.loading = false;
        this.cd.markForCheck();
        return;
      }

      const emailControl = await this.auth.emailControl(controls.email.value);

      if (emailControl.docs.length > 0) {
        this.loading = false;
        this.emailInUse = true;
        this.cd.markForCheck();
        return;
      }
      await this.auth.registerPatient(
        phone,
        controls.display_name.value,
        controls.email.value,
        +birthDate
      );
      this.router.navigate(['/auth/confirm/' + this.fullPhone]);
      this.loading = false;

      try {
        this.analyticsService.logEvent('sign_up', {
          title: 'Kayıt Olundu',
          display_name: controls.display_name.value,
          phone_number: phone,
          email: controls.email.value,
        });
      } catch (error) {

      }
    } else {
      //
      const emailControl = await this.auth.emailControl(controls.email.value);

      if (emailControl) {
        this.loading = false;
        this.emailInUse = true;
        this.cd.markForCheck();
        return;
      }

      await this.auth.registerDoctor(
        phone,
        controls.firstname.value,
        controls.lastname.value,
        controls.email.value,
        controls.school.value,
        controls.dietExperience.value,
        controls.onlineDietExperience.value,
        controls.numberOfFollowers.value,
        controls.socialMediaLinks.value,
        await this.auth.uploadCV(this.cv),
        controls.city.value,
        controls.district.value
      );
      this.loading = false;
      this.cd.markForCheck();
      this.router.navigate(['/auth/role']);
      Swal.fire({
        icon: 'success',
        confirmButtonText: 'Tamam',
        title: 'Talebini Aldık',
        text: `Askipo'ya gösterdiğin ilgi için teşekkür ederiz. Başvuru talebin bize ulaştı ve değerlendirmeye alındı. Sağlıklı günler dileriz.`,
      });
    }
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  canGoNext(controlName: string, validationType: string) {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    return control.hasError(validationType);
  }
}
