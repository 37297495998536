import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Package } from '@core/models/package.model';
import { UserPackageTrack } from '@core/models/user_packages.model';
import { endOfDay, startOfDay } from 'date-fns';
import firebase from 'firebase/app';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Appointment } from '../models/appointment.model';
import { User } from './../auth/_models/user.model';
import { Review } from './../models/review.model';
import { UserStore } from './../store/user.store';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  constructor(
    private fireStore: AngularFirestore,
    private http: HttpClient,
    private userStore: UserStore,
    private readonly fireStorage: AngularFireStorage,
    private readonly userService: UserService,
    private readonly doctorService: DoctorService,
    private fireAuth: AngularFireAuth,
  ) {
  }

  private async getHttpHeader() {
    const firebaseUser = await this.fireAuth.user.pipe(
      first()
    ).toPromise();
    const token = await firebaseUser.getIdToken();

    return new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
  }

  public currentUser(): User {
    return this.userStore.getValue().user;
  }

  public requestPermissionVoice() {
    return navigator.mediaDevices.getUserMedia({ video: false, audio: true });
  }

  public requestPermissionVoiceAndVideo() {
    return navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  }

  public getUID() {
    return this.userStore.getValue().user.uid;
  }

  public patients() {
    return this.fireStore.collection('users', query => query.where(
      'is_doctor', '==', false
    )).valueChanges({ idField: 'uid' }) as Observable<User[]>;
  }

  public reviews() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore.collection('reviews', query => query.where(
      'doctor.uid', '==', uid
    )).valueChanges({ idField: 'id' }) as Observable<Review[]>;
  }

  public answerComment(reviewID: string, answer: string) {
    return this.fireStore
      .collection('reviews')
      .doc(reviewID)
      .update({
        answer
      });
  }

  public async updatePackageInfo(profile_image: string) {
    const uid = this.userStore.getValue().user.uid;
    const packRef = await this.fireStore.collection('packages').ref.where('author.uid', '==', uid).get();
    if (packRef.empty) {
      return true;
    } else {
      if (profile_image) {
        packRef.docs[0].ref.update({
          ['author.profile_image']: profile_image
        });
      }
    }
  }

  public getLiveAppointments() {
    const uid = this.userStore.getValue().user.uid;
    const now = firebase.firestore.Timestamp.now().toMillis();
    const startDate = startOfDay(now).getTime();
    const endDate = endOfDay(now).getTime();

    return this.fireStore
      .collection('appointments',
        query => query.where('author_uid', '==', uid)
          .where('date', '>=', startDate)
          .where('date', '<=', endDate)
          .orderBy('date', 'desc')
      )
      .valueChanges({ idField: 'id' }) as Observable<Appointment[]>;
  }

  public getAppointments() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore
      .collection('appointments',
        query => query.where('author_uid', '==', uid)
          // .where('date', '>=', startDate)
          // .where('date', '<=', endDate)
          .orderBy('date', 'desc')
      )
      .valueChanges({ idField: 'id' }) as Observable<Appointment[]>
  }

  /**
   * Get appointments with the user's document inside of it.
   * @param date Date of query will be started from
   * @param limit Document amount
   * @returns Promise Array of appointment documents
   */
  public async getAppointmentsWithUsers(
    date: number | string = 'none',
    limit = 25,
  ): Promise<Array<any>> {
    const uid = this.userStore.getValue().user.uid;

    let query = this.fireStore.collection('appointments')
      .ref
      .where('author_uid', '==', uid)
      .where('assigned', '==', true)
      .orderBy('date', 'desc');

    if (date !== 'none') {
      query = query.startAfter(date);
    }

    query = query.limit(limit);

    return query.get().then((snapshot) => {
      return Promise.all(snapshot.docs.map(async (doc) => {
        if (doc.get('assigned_uid') !== 'none') {
          const userRef = await this.userService
            .getUserPromise(doc.get('assigned_uid'));

          const user = {
            ...userRef.data(),
            uid: userRef.id
          } as User;

          return {
            ...doc.data(),
            user
          };
        }
      }));
    })
      .then((appointments) => appointments.filter((appt) => appt));
  }

  public getAppointmentsBetweenDates(start$: BehaviorSubject<Date>, end$: BehaviorSubject<Date>) {
    const uid = this.userStore.getValue().user.uid;
    return combineLatest(start$, end$).pipe(switchMap(([start, end]) => {
      return this.fireStore
        .collection('appointments',
          query => query.where('author_uid', '==', uid)
            .where('date', '>=', +start)
            .where('date', '<=', +end)
            .orderBy('date', 'desc')
        )
        .valueChanges({ idField: 'id' }) as Observable<Appointment[]>;
    }));
  }

  public getFullAppointments() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore
      .collection('appointments',
        query => query.where('author_uid', '==', uid))
      .valueChanges({ idField: 'id' }) as Observable<Appointment[]>;
  }

  public getEvents() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore
      .collection('events',
        query => query.where('uid', '==', uid))
      // TODO event modeli oluşturulacak
      .valueChanges({ idField: 'id' }) as Observable<any[]>;
  }

  public getPackages() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore
      .collection('packages', query => query.where('author.uid', '==', uid))
      .valueChanges({ idField: 'id' }) as Observable<Package[]>;
  }

  public getLatestAppointment(patientUID: string) {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore.collection('appointments').ref
      .where('assigned_uid', '==', patientUID)
      .where('author_uid', '==', uid)
      .orderBy('date', 'asc').limit(1).get();
  }

  public getNextAppointment(patientUID: string) {
    const uid = this.userStore.getValue().user.uid;
    const now = firebase.firestore.Timestamp.now().toMillis();
    return this.fireStore.collection('appointments').ref
      .where('assigned_uid', '==', patientUID)
      .where('author_uid', '==', uid)
      .where('date', '>', now)
      .orderBy('date', 'asc').limit(1).get();
  }

  public async getSubscribers() {
    const uid = this.userStore.getValue().user.uid; // Kullanıcı ID'sini al
    const headers = await this.getHttpHeader();

    return this.http
      .get<UserPackageTrack[]>(`${environment.otherApiURL}expert/listactiveuserpackagebyexpertid/${uid}`, {
        headers,
      })
      .pipe(map((response: any) => response as UserPackageTrack[]));
  }

  public getWallet() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore.collection('wallets', (query) =>
      query.where('uid', '==', uid)
    ).get().toPromise();
  }

  public async getApprovedOrders() {
    const myPackage = await this.getPackages().pipe(first()).toPromise();
    if (myPackage[0]) {
      return this.fireStore.collection('orders', query =>
        query
          .where('product_id', '==', myPackage[0].id)
          .where('status', '==', 'approved'))
        .valueChanges();
    }
    return 'no-orders';
  }

  public async updatePackages(id: string, data: any): Promise<any> {
    delete data.id;
    const {
      firstname, lastname, profile_image, uid, pre_meeting, review, approved
    } = this.userStore.getValue().user;
    const average_point = review.average_point;

    const appointments = await this.fireStore.collection('appointments')
      .ref.where('author_uid', '==', uid)
      .orderBy('date', 'asc').get();

    const nearest_appointment_date = appointments.docs.length > 0 ? appointments.docs[0].data().date : 100000000000000000;

    const havePackage = await this.fireStore.collection('packages').ref
      .where('author.uid', '==', uid)
      .get();

    if (havePackage.empty) {
      return this.fireStore.collection('packages').add({
        ...data,
        author: {
          firstname, lastname, average_point,
          profile_image, uid, pre_meeting, nearest_appointment_date,
          total_review: 0
        },
        enabled: approved
      });
    } else {
      return this.fireStore.collection('packages').doc(id).update({
        ...data,
        ['author.firstname']: firstname,
        ['author.lastname']: lastname,
        ['author.average_point']: average_point,
        ['author.profile_image']: profile_image,
        ['author.uid']: uid,
        ['author.pre_meeting']: pre_meeting,
        ['author.nearest_appointment_date']: nearest_appointment_date,
        ['author.total_review']: havePackage.docs[0].get('author.total_review')
      });
    }
  }

  public async _updatePackage(data: any) {
    const uid = this.userStore.getValue().user.uid;

    const docRef = await this.fireStore.collection('packages').ref.where('author.uid', '==', uid).get();
    return docRef.docs[0].ref.update({
      author: {
        ...docRef.docs[0].data().author,
        pre_meeting: data.pre_meeting
      }
    });
  }

  public async getPsychologistPackage() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore
      .collection('psychologist_packages', query => query.where('author.uid', '==', uid))
      .valueChanges({ idField: 'id' }) as Observable<Package[]>;
  }

  public async updatePsychologistPackage(id: string, psychoPackage: any) {
    delete psychoPackage.id;
    const {
      firstname, lastname, profile_image, uid, pre_meeting, review, approved
    } = this.userStore.getValue().user;
    const average_point = review.average_point;

    const appointments = await this.fireStore.collection('appointments')
      .ref.where('author_uid', '==', uid)
      .orderBy('date', 'asc').get();

    const nearest_appointment_date = appointments.docs.length > 0 ? appointments.docs[0].data().date : 100000000000000000;

    const havePackage = await this.fireStore.collection('psychologist_packages').ref
      .where('author.uid', '==', uid)
      .get();

    if (havePackage.empty) {
      return this.fireStore.collection('psychologist_packages').add({
        ...psychoPackage,
        author: {
          firstname, lastname, average_point,
          profile_image, uid, pre_meeting, nearest_appointment_date,
          total_review: 0
        },
        enabled: approved
      });
    } else {
      return this.fireStore.collection('psychologist_packages').doc(id).update({
        ...psychoPackage,
        ['author.firstname']: firstname,
        ['author.lastname']: lastname,
        ['author.average_point']: average_point,
        ['author.profile_image']: profile_image,
        ['author.uid']: uid,
        ['author.pre_meeting']: pre_meeting,
        ['author.nearest_appointment_date']: nearest_appointment_date,
        ['author.total_review']: havePackage.docs[0].get('author.total_review')
      });
    }
  }

  public async createAppointment(data) {
    const doctor = this.userStore.getValue().user;
    const appointment: Appointment = {
      assigned: false,
      assigned_uid: 'none',
      author_uid: doctor.uid,
      date: data.date,
      minutes: 30,
      thread_id: 'none',
      completed: false,
      create_ip: '',
      doctor_note: ''
    };
    return this.fireStore.collection('appointments').add(appointment);
  }

  public async createEvent(data) {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore.collection('events')
      .add({
        uid, ...data, create_ip: (await this.ipAddress) || '',
      });
  }

  public async updateAppointment(id: string, data) {
    return this.fireStore.collection('appointments').doc(id)
      .update({
        ...data,
        modify_ip: (await this.ipAddress) || ''
      });
  }

  public async updateEvent(id: string, data) {
    this.fireStore
      .collection('events')
      .doc(id)
      .update({
        ...data,
        modify_ip: (await this.ipAddress) || ''
      });
  }

  public deleteAppointment(id: string) {
    return this.fireStore
      .collection('appointments')
      .doc(id)
      .delete();
  }

  public deleteEvent(id: string) {
    return this.fireStore
      .collection('events')
      .doc(id)
      .delete();
  }

  public async deleteDocument(url: string, type: string, newValue) {
    const uid = this.userStore.getValue().user.uid;
    await this.fireStore.collection('users').doc(uid).update({ ['documents.' + type]: newValue });
    const ref = this.fireStorage.storage.refFromURL(url);
    return ref.delete();
  }

  public getUserPackage(clientID: string) {
    return this.fireStore
      .collection('package_tracking')
      .ref.where('uid', '==', clientID)
      .where('pkg_author_id', '==', this.getUID())
      .where('enabled', '==', true)
      .limit(1)
      .get()
      .then(data => {
        return data.empty ? false : { ...data.docs[0].data(), id: data.docs[0].id } as UserPackageTrack;
      });
  }

  public getPatientDetail(uid: string) {
    return this.fireStore.collection('public_users').doc(uid).ref.get().then((result) => {
      return result.data();
    });
  }

  public get ipAddress() {
    return this.http.get(environment.ipURL).toPromise() as Promise<string>;
  }

  public async getPackageDetail(uid: string) {
    const currentUser = this.currentUser();
    const expertId = currentUser.uid;

    const httpResult = await this.http.get(environment.otherApiURL + `expert/userpackage/${uid}/${expertId}`,
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise();

    return httpResult;
  }

  public async getMeetingId(uid: string) {
    const currentUser = this.currentUser();
    const expertId = currentUser.uid;

    const packageId: any = await this.getPackageDetail(uid);

    const httpResult: any = await this.http.post(environment.otherApiURL + 'expert/usermeeting',
      {
        Uid: uid,
        ExpertId: expertId,
        UserExpertPackageId: packageId.id,
      },
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise()

    localStorage.setItem('meetingId', httpResult.id)

    return httpResult;
  }

  public async setCompleteMeeting() {
    console.log(typeof localStorage.getItem('meetingId'));
    if (typeof localStorage.getItem('meetingId') !== 'undefined') {
      const httpResult: any = this.http.get(environment.otherApiURL + 'expert/completemeeting/' + localStorage.getItem('meetingId'),
        {
          headers: await this.getHttpHeader(),
        }
      ).toPromise();

      localStorage.removeItem('meetingId');

      return httpResult;
    } else {
      return null;
    }
  }
}
