import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Appointment } from '@core/models/appointment.model';
import { Message } from '@core/models/message.model';
import { Thread } from '@core/models/thread.model';
import { UserPackageTrack } from '@core/models/user_packages.model';
import { AgoraService } from '@core/services/agora.service';
import { AnalyticsService } from '@core/services/analytics.service';
import { DoctorService } from '@core/services/doctor.service';
import { PatientService } from '@core/services/patient.service';
import { ThreadService } from '@core/services/thread.service';
import { TokboxService } from '@core/services/tokbox.service';
import { UserStore } from '@core/store/user.store';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { addMinutes } from 'date-fns';
import firebase from 'firebase/app';
import moment from 'moment';
import { combineLatest, Subscription } from 'rxjs';
import Swal from 'sweetalert2';

declare var MediaRecorder: any;

@Component({
  selector: 'kt-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {

  appointments: Appointment[];
  threads: Thread[];
  liveThread = '';
  liveThreadType: string;
  preMeeting: boolean;

  thread: Thread;

  messages: Message[] = [];

  packages: UserPackageTrack;

  UID: string;

  messageText = '';

  currentTab = 1;

  loading: boolean;

  initedMessages: boolean;

  messagesAre: 'loading' | 'loaded' | 'full';

  mediaRecorder;

  recording = false;

  timer = 0;
  timerInterval;

  isDoctor;

  openTok: boolean;

  calledOnce: boolean;

  canStream: boolean;

  threadSubscription: Subscription;
  messageSubscription: Subscription;
  micSubscription: Subscription;
  callEndedSub: Subscription;


  @ViewChild('chatScroll', { static: false }) chatScroll: ElementRef;
  @ViewChild('reviewSwal', { static: true }) reviewSwal: SwalComponent;

  scrollOptions = {
    distance: .5,
    throttle: 0
  };

  call = {
    video: false,
    voice: false,
    state: '',
    type: ''
  };

  inited = false;

  review = {
    comment: '',
    point: 0
  };

  controlTimeout: any;

  constructor(
    private threadService: ThreadService,
    private userStore: UserStore,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private tokService: TokboxService,
    private analyticsService: AnalyticsService,
    private patientService: PatientService,
    private sanitizer: DomSanitizer,
    private agoraService: AgoraService,
    private doctorService: DoctorService
  ) {
  }

  ngOnInit() {
    this.threadService.setStatusFilter(1);
    const now = firebase.firestore.Timestamp.now().toMillis();

    this.isDoctor = this.userStore.getValue().user.is_doctor;
    this.packages = this.userStore.getValue().package;

    // console.log('packages:', this.packages);

    this.UID = this.userStore.getValue().user.uid;
    const threads = this.threadService.getAllThreads();
    const appointments = this.threadService.getAllAppointments();

    // threads.subscribe((val) => console.log('THREAD:', val));

    this.callEndedSub = this.agoraService.isCallEnded$.subscribe(async (res) => {
      if (res && !this.isDoctor) {
        const reviewed = await this.threadService.hasReviewed(this.thread.doctor.uid);
        if (!reviewed) {
          this.reviewSwal.fire();
        }
      }

      if (this.isDoctor && this.thread) {
        const threadID = this.thread.id;
          Swal.fire({
            title: 'Görüşmeyi Tamamla',
            text: 'Görüşmenin bittiğinden emin misin?',
            confirmButtonText: 'Evet',
            confirmButtonColor: '#EB5D29',
            cancelButtonText: 'Hayır',
            showCancelButton: true,
            reverseButtons: true,
          }).then(res => {
            console.log('complete meeting:', res);
            if (res.value) {
              // const appointment = this.appointments.find(appt => appt.thread_id === threadID);
              // this.threadService.completeAppointment(appointment.id);
              this.doctorService.setCompleteMeeting();
            }
          });
      }
    });

    combineLatest([threads, appointments]).subscribe(async ([_threads, _appointments]) => {
      // console.log('something aupdated');

      this.appointments = _appointments;
      await Promise.all(_threads.map(async (thread, i) => {
        const appointment = this.appointments.find((appt) => appt.thread_id === thread.id);
        if (!appointment) {
          return;
        }

        try {
          // const havePackage = await this.patientService.hasEnabledPackage(thread.patient.uid);

          // if (!havePackage && appointment.pre_meeting !== true) {
          //   _threads.splice(i, 1);
          // }

          this.cd.detectChanges();
        } catch (err) {
          console.log(err);
        }

        if (moment(now).isBetween(appointment.date, addMinutes(appointment.date, appointment.minutes))) {
          if (this.controlTimeout) {
            clearTimeout(this.controlTimeout);
          }

          if (!appointment.completed) {
            const timeLeft = moment(now).diff(addMinutes(appointment.date, appointment.minutes));

            this.controlTimeout = setTimeout(() => {
              this.liveThreadType = '';
              this.liveThread = '';
            }, Math.abs(timeLeft));
            this.liveThread = thread.id;
            this.liveThreadType = appointment.type;
            this.preMeeting = appointment.pre_meeting;
          } else {
            this.liveThread = '';
            this.liveThreadType = '';
            this.preMeeting = false;
          }
        }
      }));

      this.threads = _threads;

      if (!this.inited) {
        const id = this.route.snapshot.params.id;
        if (id !== 'view') {
          this.selectThread(id).then((thread: Thread) => {
            const type = this.route.snapshot.params.answer;
            if (type) {
              if (thread.call[type] === 0) {
                this.threadService.sendCall(id, type);
              } else {
                this.threadService.callChange(id, type, 2);
              }
            }
          });
        } else {
          if (this.threads.length > 0) {
            this.selectThread(this.threads[0].id);
          }
        }
        this.inited = true;
        // console.log('a');
      }
    });

    if (!this.isDoctor) {
      this.analyticsService.logScreen('Mesajlaşma');
    }
  }

  getStatus() {
    return this.threadService.getStatusFilter();
  }

  filter(status: number) {
    if (this.threadService.getStatusFilter() === status) {
      return false;
    }

    this.currentTab = status;
    this.threadService.setStatusFilter(status);
  }

  async selectThread(threadId: string, thread?: Thread) {
    return new Promise((resolve) => {
      const now = firebase.firestore.Timestamp.now().toMillis();
      const _package = this.packages;

      const appointment = this.appointments.find((appt) => appt.thread_id === threadId);

      // console.log('appointment:', appointment);

      this.canStream =
        appointment
          ? moment(now).isBetween(appointment.date, addMinutes(appointment.date, appointment.minutes))
          : false;

      if (threadId === 'none') {
        this.router.navigate(['appointments/detail/' + appointment.id]);
        return;
      }
      if (this.thread && this.thread.id === threadId) {
        return;
      }

      if (this.threadSubscription) {
        this.threadSubscription.unsubscribe();
      }

      this.loading = true;
      this.initedMessages = false;

      this.threadSubscription = this.threadService.thread(threadId)
        .subscribe(data => {
          if (!this.thread) {
            this.thread = data;
            this.thread.id = threadId;
            this.seeThread();
          }
          // this.callControl(data.call.video, data.call.voice, data.call.request_author_uid);
          this.threadService.setActiveThread(data);
          this.thread = data;
          this.thread.id = threadId;
          resolve(this.thread);
        });


      this.messagesAre = 'loaded';
      this.messages = [];

      if (this.messageSubscription) {
        this.messageSubscription.unsubscribe();
      }

      this.messageSubscription = this.threadService.getMessages(threadId, 3)
        .subscribe(val => {
          this.messages = this.messages.concat(val.filter(msg => {
            // console.log(this.messages.filter(x => x.id === msg.id).length);
            return this.messages.filter(x => x.id === msg.id).length === 0 && !msg.is_deleted;
          }));
          try {
            this.cd.detectChanges();
          } catch (err) {

          }
          this.chatScrollToBottom(100);
        });

      this.loading = false;

      this.chatScrollToBottom(500);
    });
  }

  sendCall(type: string) {
    this.threadService.sendCall(this.thread.id, type).then(res => console.log('sentcall:', res));
  }

  private async callControl(video: number, voice: number, requestUID: string) {
    // console.log('call control:', {
    //   video,
    //   voice
    // });

    // görüşme sonlandırıldı durumu
    if (video === 0 && voice === 0) {
      this.call.video = false;
      this.call.voice = false;
      this.tokService.openTok.next(false);
      if (this.calledOnce && !this.isDoctor) {
        // console.log('1bm');
        const reviewed = await this.threadService.hasReviewed(this.thread.doctor.uid);
        if (!reviewed) {
          this.reviewSwal.fire();
        }
      }
      if (this.calledOnce && this.isDoctor) {
        const threadID = this.thread.id;
        Swal.fire({
          title: 'Görüşmeyi Tamamla',
          text: 'Görüşmenin bittiğinden emin misin?',
          confirmButtonText: 'Evet',
          confirmButtonColor: '#EB5D29',
          cancelButtonText: 'Hayır',
          showCancelButton: true,
          reverseButtons: true,
        }).then(res => {
          console.log('complete meeting:', res);
          if (res.value) {
            const appointment = this.appointments.find(appt => appt.thread_id === threadID);
            this.threadService.completeAppointment(appointment.id);
          }
        });
        this.calledOnce = false;
      }
      try {
        this.cd.detectChanges();
      } catch (err) {

      }
    }


    // çalıyor / arıyor durumu
    if (!this.tokService.openTok.value && (video === 1 || voice === 1)) {

      // görüntülü arama
      if (video === 1) {
        this.call.video = true;
        this.call.type = 'video';
        this.tokService.type = 'video';
      }

      // sesli arama
      if (voice === 1) {
        this.call.voice = true;
        this.call.type = 'voice';
        this.tokService.type = 'voice';
      }

      this.call.state = this.userStore.getValue().user.uid === requestUID ?
        'calling' : 'called';
      // console.log('call state:', this.call.state);
      this.calledOnce = false;
      try {
        this.cd.detectChanges();
      } catch (err) {

      }
    }

    // görüşme başlatıldı durumu
    if (!this.tokService.openTok.value && (video === 2 || voice === 2)) {
      this.tokService.openTok.next(true);
      // console.log('open tok');
      this.call.state = 'oncall';
      try {
        this.cd.detectChanges();
      } catch (err) {

      }
      this.calledOnce = true;
    }

    // console.log('call control:', {
    //   video,
    //   voice
    // });
  }

  seeThread() {
    this.threadService.seenUpdate(this.thread.id, this.userStore.getValue().user.uid, this.isDoctor);
  }

  async onFileUpload(event) {
    const file = event.target.files[0] as Blob;

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async () => {
      const res = reader.result as string;
      const base64 = res.split(',')[1];

      const contentType = res.split(',')[0].split(':')[1].split(';')[0];

      if (!file) {
        return;
      }
      const messageResult = await this.sendMessage(undefined, 'other');
      console.log(messageResult);
      const uploadResult = await this.threadService.uploadFileV3(
        base64, 'other', this.thread.id, messageResult.data, contentType
      );

      let message = this.messages.find(msg => msg.id === messageResult.data);
      message.upload_status = true;
      message.files = uploadResult as any;
      try {
        this.cd.detectChanges();
      } catch (err) {

      }
    }
  }

  chatScrollToBottom(delay: number) {
    setTimeout(() => {
      this.chatScroll.nativeElement.scrollTo({ behavior: 'smooth', top: this.chatScroll.nativeElement.scrollHeight });
    }, delay);
  }

  async onScroll() {
    const threadID = this.thread.id;
    // console.log('thread id:', threadID);
    if (this.messagesAre === 'full' || this.thread.id !== threadID) {
      return;
    }

    this.messagesAre = 'loading';
    const newMessages = await this.threadService.prevMessages(this.thread.id, this.messages[0].date) as Message[];

    if (newMessages.length === 0) {
      this.messagesAre = 'full';
      try {
        this.cd.detectChanges();
      } catch (err) {

      }
      return;
    }

    // Tam mesaj yükleniyorken chat değiştirilirse
    if (this.thread.id !== threadID) {
      return;
    }

    this.messages = newMessages.concat(this.messages);

    setTimeout(() => {
      try {
        this.cd.detectChanges();
      } catch (err) {

      }
      this.chatScroll.nativeElement.scrollTo({ top: 600 });
      this.messagesAre = 'loaded';
    }, 500);
  }

  async sendMessage(event?, fileType?) {
    if (event) {
      event.preventDefault();
    }
    console.log('pre meeting', this.preMeeting);
    if (this.getStatus() === 2) {
      return;
    }
    const message = this.messageText.trim();
    this.messageText = '';
    console.log('e')

    if ((fileType === undefined && message === '') || this.thread.status === 2) {
      return;
    }
    console.log('e')

    const messageID = await this.threadService.sendMessage(
      this.thread.id,
      message,
      this.isDoctor,
      fileType
    );

    console.log('e')

    this.chatScrollToBottom(100);
    console.log('e')

    return {
      status: false,
      data: messageID
    };
  }

  trackByFn(index, item: Message) {
    return item.id;
  }

  openProfile(uid: string) {
    this.router.navigate([`profile/view/${uid}`]);
  }

  ngOnDestroy(): void {
    if (this.micSubscription) {
      this.micSubscription.unsubscribe();
    }
    if (this.threadSubscription) {
      this.threadSubscription.unsubscribe();
    }
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  sendReview() {
    const doctor = {
      firstname: this.thread.doctor.firstname,
      lastname: this.thread.doctor.lastname,
      uid: this.thread.doctor.uid,
      profile_image: this.thread.doctor.profile_image
    } as any;
    this.threadService.reviewDoctor(doctor, this.review.comment, this.review.point).then(() => {
      Swal.fire('Başarılı', 'Değerlendirmen gönderildi!', 'success');
    });
  }

  public byPass(imageUrl: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
  }
}
