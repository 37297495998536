import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@environments/environment';
import { AgoraClient, ClientEvent, NgxAgoraService, Stream, StreamEvent } from 'ngx-agora';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DoctorService } from './doctor.service';

@Injectable({
  providedIn: 'root'
})
export class AgoraService {
  public agoraClient: AgoraClient;
  public localStream: Stream = null;
  public audio: boolean = false;
  public video: boolean = false;
  public thread: any;
  private threadId: string;
  private callIsActive: boolean;

  private isStreamPublished = new BehaviorSubject<boolean>(false);
  isStreamPublished$ = this.isStreamPublished.asObservable();

  private isCallEnded = new BehaviorSubject<boolean>(false);
  isCallEnded$ = this.isCallEnded.asObservable();

  constructor(
    private ngxAgoraService: NgxAgoraService,
    private fireStore: AngularFirestore,
    private httpClient: HttpClient,
    private doctorService: DoctorService,
  ) {
  }

  public localVideoElement: HTMLElement;
  public remoteVideoElement: HTMLElement;
  public remoteAudioElement: HTMLElement;

  public setVideoElements(local: HTMLElement, remote: HTMLElement): void {
    this.localVideoElement = local;
    this.remoteVideoElement = remote;
  }

  public setAudioElements(remote: HTMLAudioElement): void {
    if (!remote) {
      return;
    }
    
    if (!(remote instanceof HTMLAudioElement)) {
      return;
    }
    
    this.remoteAudioElement = remote;
  }

  public async initialize(isVideoCall: boolean = false): Promise<void> {
    try {
      this.ngxAgoraService.AgoraRTC.Logger.setLogLevel(this.ngxAgoraService.AgoraRTC.Logger.NONE);

      if ((!this.localVideoElement || !this.remoteVideoElement ) && isVideoCall) {
        throw new Error('Video elements not properly set');
      }

      this.audio = true;
      this.video = isVideoCall;

      const thread$ = await this.threadListener();
      
      thread$.subscribe((response) => {
        if (!response) {
          this.closeChannel();
          return;
        }

        if (response === 'skip') {
          return;
        }

        if (response.callIsActive) {
          this.createEngine(response.threadId, response.userDetail);
        }
      });
    } catch (error) {
      throw error;
    }
  }

  private async createEngine(threadId: string, userDetail: any): Promise<void> {
    try {
      const permValidate = await this.permissionValidate(threadId);
      if (!permValidate) {
        return;
      }

      this.agoraClient = this.ngxAgoraService.createClient({ codec: "h264", mode: "rtc" });
      
      this.assignClientHandlers(this.agoraClient);

      this.agoraClient.init(environment.agoraAppId,
        async () => {
          this.joinChannel(threadId);

          if (this.thread.patient.uid) {
            await this.doctorService.getMeetingId(this.thread.patient.uid);
          }
        },
        (err: Error) => {
          this.closeChannel(threadId);
        }
      );
    } catch (error) {
      this.closeChannel(threadId);
    }
  }

  private assignClientHandlers(client: AgoraClient): void {
    client.on(ClientEvent.LocalStreamPublished, () => {});

    client.on(ClientEvent.Error, (error) => {
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        client.renewChannelKey('');
      }
    });

    client.on(ClientEvent.RemoteStreamAdded, (evt) => {
      const stream = evt.stream as Stream;
      client.subscribe(stream, { audio: this.audio, video: this.video }, err => {});
    });

    client.on(ClientEvent.RemoteStreamSubscribed, async (evt) => {
      const stream = evt.stream as Stream;
      if(this.video) { 
        if (this.remoteVideoElement.id) {
          stream.play(this.remoteVideoElement.id);
        }
      } else {
        if (this.remoteAudioElement && this.remoteAudioElement.id) {
          try {
            stream.play(this.remoteAudioElement.id);
          } catch (error) {}
        }
      }
    });

    client.on(ClientEvent.RemoteStreamRemoved, (evt) => {
      const stream = evt.stream as Stream;
      stream.stop();
      this.isCallEnded.next(true);
    });

    client.on(ClientEvent.PeerLeave, () => {
      this.isCallEnded.next(true);
    });
  }

  private async permissionValidate(threadId: string): Promise<boolean> {
    try {
      const result = await this.doctorService.requestPermissionVoice();

      if (!result) {
        await this.saveLog('PERMISSION_REQUIRED');
        alert('Danışanınla görüşmeni gerçekleştirebilmen için uygulamamızın mikrofonuna erişmesine izin vermen gerekmektedir.');
        this.closeChannel(threadId);
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  private async joinChannel(threadId: string): Promise<void> {
    try {
      await this.startLocalStream();
      
      const { token, uid, status } = await this.createAccessToken(threadId);
      if (!status || !token) {
        throw new Error('Failed to get access token');
      }

      this.agoraClient.join(token, threadId, uid,
        () => {
          this.agoraClient.publish(this.localStream, (err) => {});
        },
        (err) => {
          this.closeChannel(threadId);
        }
      );

      this.isStreamPublished.next(true);

    } catch (error) {
      this.closeChannel(threadId);
    }
  }

  private async createAccessToken(channelId: string): Promise<{ status: boolean, token: string, uid: string }> {
    try {
      const user = this.doctorService.currentUser();
      const URL = `${environment.apiURL}app/webrtc/agora-create-token`;

      const response: any = await this.httpClient.post(URL, {
        threadId: channelId,
        uid: user.uid,
      }).toPromise();

      return { status: true, token: response.token, uid: user.uid };
    } catch (error) {
      return { status: false, token: '', uid: '' };
    }
  }

  public async startLocalStream(): Promise<void> {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: this.video
      });

      const audioTrack = mediaStream.getAudioTracks()[0];
      const videoTrack = this.video ? mediaStream.getVideoTracks()[0] : null;

      const streamConfig: any = {
        streamID: this.threadId,
        audio: true,
        video: this.video,
        screen: false,
        audioSource: audioTrack,
        videoSource: videoTrack,
      };

      this.localStream = this.ngxAgoraService.createStream(streamConfig);
      this.assignLocalStreamHandlers(this.localStream);

      await this.localStream.init(() => {
        if(this.video && this.localVideoElement.id) {
          this.localStream.play(this.localVideoElement.id);
        }
      }, (err) => {
        throw err;
      });
    } catch (error) {
      throw error;
    }
  }

  private assignLocalStreamHandlers(localStream: Stream): void {
    try {
      localStream.on(StreamEvent.MediaAccessAllowed, () => {});

      localStream.on(StreamEvent.MediaAccessDenied, () => {
        this.closeChannel(this.threadId);
      });

      localStream.on(StreamEvent.AudioTrackEnded, () => {});

      if (this.video) { 
        localStream.on(StreamEvent.VideoTrackEnded, () => {});
      }
    } catch (error) {}
  }

  public closeChannel(threadId: string = 'none'): void {
    try {
      if (threadId !== 'none') {
        this.fireStore.collection('threads').doc(threadId).update({
          ['call.voice']: 0,
          ['call.video']: 0,
        }).catch((err) => {});
      }

      if (this.agoraClient) {
        this.agoraClient.leave(() => {
          this.isCallEnded.next(true);
        }, (err) => {});
      }

      this.isStreamPublished.next(false);
      this.callIsActive = false;

      if (this.localStream && this.agoraClient) {
        this.agoraClient.unpublish(this.localStream, (error) => {});
        this.localStream.stop();
        this.localStream.close();
        this.localStream = null;
      }
    } catch (error) {}
  }

  private async saveLog(data: string): Promise<any> {
    try {
      const user = this.doctorService.currentUser();
      const logEntry = {
        thread_id: this.threadId,
        uid: user.uid,
        log: data,
        date: new Date().getTime(),
      };
      await this.fireStore.collection('webrtc_logs').add(logEntry);
      return true;
    } catch (error) {
      return null;
    }
  }

  private async threadListener(): Promise<Observable<any>> {
    try {
      const user = this.doctorService.currentUser();

      const voiceQuery = this.fireStore.collection('threads', (ref) =>
        ref.where('doctor.uid', '==', user.uid)
           .where('call.voice', '>=', 1)
           .limit(1)
      ).valueChanges({ idField: 'id' });

      const videoQuery = this.fireStore.collection('threads', (ref) =>
        ref.where('doctor.uid', '==', user.uid)
           .where('call.video', '>=', 1)
           .limit(1)
      ).valueChanges({ idField: 'id' });

      return combineLatest([voiceQuery, videoQuery]).pipe(
        map(([voiceThreads, videoThreads]) => {
          const combinedThreads = voiceThreads.concat(videoThreads);

          if (combinedThreads.length === 0) {
            return null;
          }

          const uniqueThreads = combinedThreads.filter(
            (thread, index, self) => index === self.findIndex(t => t.id === thread.id)
          );

          const thread = uniqueThreads[0] as any;
          this.thread = thread;
          this.threadId = thread.id;
          const callIsActive = thread.call.voice === 2 || thread.call.video === 2;

          if (this.callIsActive && callIsActive) {
            return 'skip';
          }

          this.callIsActive = callIsActive;
          const userDetail = {
            uid: thread.patient.uid,
            image: thread.patient.profile_image,
            name: thread.patient.display_name,
          };

          return { callIsActive, userDetail, threadId: thread.id };
        })
      );
    } catch (error) {
      return null;
    }
  }

  public muteAudio(): void {
    if (this.localStream) {
      this.localStream.muteAudio();
    }
  }

  public unmuteAudio(): void {
    if (this.localStream) {
      this.localStream.unmuteAudio();
    }
  }

  public muteVideo(): void {
    if (this.localStream) {
      this.localStream.muteVideo();
    }
  }

  public unmuteVideo(): void {
    if (this.localStream) {
      this.localStream.unmuteVideo();
    }
  }
}
