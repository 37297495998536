import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
// import { TokboxService } from '../../../../core/services/tokbox.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AgoraService } from '@core/services/agora.service';
import { UserStore } from '@core/store/user.store';
import { map } from 'rxjs/operators';
import { ThreadService } from '../../../../core/services/thread.service';

@Component({
  selector: 'kt-webrtc-audio',
  templateUrl: './webrtc-audio.component.html',
  styleUrls: ['./webrtc-audio.component.scss']
})
export class WebrtcAudioComponent implements OnInit, OnDestroy {
  @ViewChild('remoteAudio', { static: true }) remoteAudio!: ElementRef<HTMLAudioElement>;
  
  @Input() threadID!: string;
  @Input() userUID!: string;
  @Input() isDoctor!: boolean;

  public remoteStatus: boolean = false;
  public isStreamPublished: boolean = false;
  public micStatus: boolean = true;
  public audioStatus: boolean = true;
  
  private threadSub?: Subscription;
  public otherUser: Observable<any>;

  public time = {
    count: 0,
    text: '00:00:00',
    interval: null as any
  };

  constructor(
    private threadService: ThreadService,
    private store: UserStore,
    private fireStore: AngularFirestore,
    public agoraService: AgoraService
  ) {
    console.log('[WebrtcAudioComponent] Initialized');
  }

  async ngOnInit() {
    try {
      console.log('[WebrtcAudioComponent] Starting initialization');
      
      // Other user data subscription
      this.otherUser = this.fireStore.collection('public_users')
        .doc(this.userUID)
        .valueChanges();

      // Audio element kontrolü
      if (!this.remoteAudio.nativeElement) {
        console.error('[WebrtcAudioComponent] Audio element not found');
        throw new Error('Audio element not found');
      }

      console.log('[WebrtcAudioComponent] Requesting media permissions');
      // Browser izinlerini kontrol edelim
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });

      // Stream track kontrolü
      const audioTracks = stream.getAudioTracks();
      console.log('[WebrtcAudioComponent] Initial Audio Tracks:', audioTracks);
      
      if (audioTracks.length === 0) {
        console.error('[WebrtcAudioComponent] No audio tracks found');
        throw new Error('No audio tracks found');
      }

      // Audio elementini servise geçelim
      this.agoraService.setAudioElements(this.remoteAudio.nativeElement);
      console.log('[WebrtcAudioComponent] Audio element set in service');

      // Initialize işlemini başlatalım
      await this.agoraService.initialize(false);
      console.log('[WebrtcAudioComponent] Agora service initialized');

      // Stream published subscription
      this.agoraService.isStreamPublished$.subscribe((res) => {
        console.log('[WebrtcAudioComponent] Stream Published status:', res);
        this.isStreamPublished = res;
        
        if (res) {
          this.startTimer();
        }
      });

      // Thread subscription for non-doctor users
      if (!this.isDoctor) {
        console.log('[WebrtcAudioComponent] Setting up thread subscription for patient');
        this.setupThreadSubscription();
      }

    } catch (error) {
      console.error('[WebrtcAudioComponent] Error in initialization:', error);
      this.finishCall();
    }
  }

  private setupThreadSubscription(): void {
    this.threadSub = this.threadService.thread(this.threadID).pipe(
      map((value) => {
        this.remoteStatus = value['connect_status']['doctor'];
        console.log('[WebrtcAudioComponent] Remote status updated:', this.remoteStatus);

        if (this.remoteStatus && value['connect_status']['patient']) {
          console.log('[WebrtcAudioComponent] Both participants connected, initializing call');
          setTimeout(async () => {
            try {
              await this.agoraService.initialize(false);
            } catch (error) {
              console.error('[WebrtcAudioComponent] Error initializing call:', error);
              this.finishCall();
            }
          }, 1000);
        }
      })
    ).subscribe();
  }

  private startTimer(): void {
    console.log('[WebrtcAudioComponent] Starting call timer');
    this.time.interval = setInterval(() => {
      this.time.count += 1;
      const dateObj = new Date(this.time.count * 1000);
      const hours = dateObj.getUTCHours();
      const minutes = dateObj.getUTCMinutes();
      const seconds = dateObj.getSeconds();

      this.time.text = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds.toString().padStart(2, '0');
    }, 1000);
  }

  public changeMic(): void {
    console.log('[WebrtcAudioComponent] Toggling microphone');
    if (this.micStatus) {
      this.agoraService.muteAudio();
      this.micStatus = false;
      console.log('[WebrtcAudioComponent] Microphone muted');
    } else {
      this.agoraService.unmuteAudio();
      this.micStatus = true;
      console.log('[WebrtcAudioComponent] Microphone unmuted');
    }
  }

  public changeAudio(): void {
    console.log('[WebrtcAudioComponent] Toggling audio output');
    if (!this.remoteAudio.nativeElement) {
      console.error('[WebrtcAudioComponent] Remote audio element not found');
      return;
    }

    this.audioStatus = !this.audioStatus;
    const audioElement = this.remoteAudio.nativeElement;

    try {
      audioElement.volume = this.audioStatus ? 1 : 0;
      audioElement.muted = !this.audioStatus;
      console.log(`[WebrtcAudioComponent] Audio ${this.audioStatus ? 'enabled' : 'disabled'}`);
    } catch (error) {
      console.error('[WebrtcAudioComponent] Error changing audio state:', error);
    }
  }

  public finishCall(): void {
    console.log('[WebrtcAudioComponent] Finishing call');
    this.agoraService.closeChannel(this.threadID);
    this.threadService.setActiveThread(null);
    
    if (this.time.interval) {
      clearInterval(this.time.interval);
      console.log('[WebrtcAudioComponent] Timer cleared');
    }
  }

  ngOnDestroy(): void {
    console.log('[WebrtcAudioComponent] Component destroying');
    this.finishCall();

    if (this.threadSub) {
      this.threadSub.unsubscribe();
      console.log('[WebrtcAudioComponent] Thread subscription unsubscribed');
    }
  }
}
